import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../components/layout/layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = (props, location) => <Layout seoTitle="Privacy Policies" description="Privacy policies for my projects" type="🕵️‍♂️" location={location} {...props} />;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "privacy-policies"
    }}>{`Privacy Policies`}</h1>
    <p>{`Welcome to the Privacy Policies page for the projects I maintain.`}</p>
    <h2 {...{
      "id": "introduction-to-hebrew"
    }}>{`Introduction to Hebrew`}</h2>
    <p>{`Privacy Policy for Introduction to Hebrew / Introduction to Hebrew Vocab`}</p>
    <p>{`Introduction to Hebrew (the “App”) respects your privacy and does not collect, store, or share any user information. By using this App, you acknowledge and agree to this Privacy Policy. We reserve the right to update or change this policy without notice.`}</p>
    <h2 {...{
      "id": "mileage-metrics"
    }}>{`Mileage Metrics`}</h2>
    <p>{`Privacy Policy for Mileage Metrics`}</p>
    <p>{`Mileage Metrics (the “App”) respects your privacy and does not collect, store, or share any user information. By using this App, you acknowledge and agree to this Privacy Policy. We reserve the right to update or change this policy without notice.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      